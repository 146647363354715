import Plaatjie from '@ubo/plaatjie'
import React from 'react'
import styled from 'styled-components'

const StyledBannerWorkAt = styled.section`
  height: 802px;

  @media (max-width: 991px) {
    height: 520px;
  }
`

const StyledBackgroundImage = styled(Plaatjie)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const PointsDiv = styled.div`
  height: 120px;
  padding: 0 10px;
`

const PointsDivInner = styled.div`
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background-color: #f5f5f5;
`

const Oval = styled.div`
  width: 18px;
  height: 18px;
  border: 4px solid #ff6c1d;
  border-radius: 50%;
`

const Title = styled.h4`
  font-family: ${(props) => props.theme.font.family.secondary};
  color: ${(props) => props.theme.color.face.main};
  font-size: ${(props) => props.theme.font.size['30']};
`

const QuickMenu = styled.div`
  background-color: #f5f5f5;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;

  & p,
  a {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: 21px;
    line-height: 40px;
    color: ${(props) => props.theme.color.text.main} !important;

    @media (max-width: 991px) {
      font-size: 18px;
    }
  }

  & a {
    font-weight: 300;

    &:hover {
      text-decoration: underline;
    }
  }
`

const Menu = styled.ul`
  list-style: none;
  width: 80%;
  margin-left: -2.3rem;

  @media (max-width: 991px) {
    width: 100%;

    & li {
      width: 100%;
    }
  }
`

const QuickMenuContainer = styled.div`
  @media (min-width: 1200px) {
    max-width: 1340px !important;
  }
`

interface BannerWorkAtProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
}

const BannerWorkAt: React.FC<BannerWorkAtProps> = ({ fields }) => (
  <StyledBannerWorkAt className="position-relative">
    <QuickMenu className="position-absolute">
      <QuickMenuContainer className="container">
        <div className="row py-3 py-lg-5 px-3 px-lg-0 align-items-center justify-content-between">
          <p className="mb-3 mb-lg-0">Direct naar:</p>
          <Menu className="d-flex flex-wrap align-items-center justify-content-between mb-0">
            <li>
              <a href="#vacatures" className="d-flex align-items-center">
                <Oval />
                <span className="ml-3">Vacatures</span>
              </a>
            </li>
            {/* <li>
              <a href="#dagelijks-werk" className="d-flex align-items-center">
                <Oval />
                <span className="ml-3">Dagelijks werk</span>
              </a>
            </li> */}
            <li>
              <a href="#testimonials" className="d-flex align-items-center">
                <Oval />
                <span className="ml-3">Testimonials</span>
              </a>
            </li>
            <li>
              <a href="#gallery" className="d-flex align-items-center">
                <Oval />
                <span className="ml-3">Collega's en werkruimte</span>
              </a>
            </li>
          </Menu>
        </div>
      </QuickMenuContainer>
    </QuickMenu>
    <StyledBackgroundImage image={fields?.image} alt="" />
    <div className="container h-100">
      <div className="row justify-content-center align-items-end h-100 d-none d-lg-flex">
        {fields?.points?.map((item, index) => (
          <PointsDiv
            className="col-lg-4 d-flex align-items-center justify-content-center"
            key={index}
          >
            <PointsDivInner className="w-100 h-100 d-flex align-items-center justify-content-center">
              <Oval />
              <Title className="mb-0 text-uppercase ml-3">{item?.item}</Title>
            </PointsDivInner>
          </PointsDiv>
        ))}
      </div>
    </div>
  </StyledBannerWorkAt>
)

export default BannerWorkAt
