/* eslint-disable no-undef */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'
import ButtonDefault from 'components/elements/Button/ButtonDefault'

interface BannerProductDetailProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
}

const StyledBannerProductDetail = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  height: 795px;
  position: relative;

  @media screen and (max-width: 991px) {
    height: fit-content;
  }

  @media screen and (max-width: 576px) {
    padding: 0 0 0px 0;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.text.light};
  padding-top: 50px;
  position: relative;
  z-index: 2;

  & img {
  }

  & p {
    @media screen and (max-width: 576px) {
      margin-bottom: 0;
    }
  }

  & h1,
  h2 {
    font-size: ${(props) => props.theme.font.size[65]};
    line-height: 65px;
    font-family: ${(props) => props.theme.font.family.secondary};
    position: relative;

    & em {
      position: absolute;
      left: 85%;
      width: 700px;
      line-height: 30px;
      padding-top: 15px;
      font-size: ${(props) => props.theme.font.size[25]};
      font-weight: ${(props) => props.theme.font.weight.s};

      @media screen and (max-width: 1199px) {
        left: 0;
        top: 50px;
        width: 1000px;
      }

      @media screen and (max-width: 991px) {
        font-size: ${(props) => props.theme.font.size[18]};
        white-space: normal;
        line-height: 18px;
        top: 75px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      @media screen and (max-width: 576px) {
        top: 65px;
      }
    }

    @media screen and (max-width: 768px) {
      font-size: ${(props) => props.theme.font.size['80']};
      line-height: 80px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 576px) {
      font-size: ${(props) => props.theme.font.size['60']};
      line-height: 60px;
      padding-right: 15px;
      padding-bottom: 20px;
    }

    @media screen and (max-width: 400px) {
      font-size: 50px;
    }
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 50px;
  }
`

const StyledBackgroundImage = styled(Plaatjie)`
  opacity: 0.18;
  z-index: 1;
  height: 100%;
  width: 100%;
  position: absolute !important;
  pointer-events: none;
`

const StyledImage = styled(Plaatjie)`
  height: auto;
  width: 450px;
  position: relative;
  bottom: 80px;
  z-index: 10;
  max-height: 600px;

  & img {
    object-fit: contain !important;
  }

  @media screen and (max-width: 991px) {
    display: none !important;
  }
`

const StyledIcon = styled(Plaatjie)`
  height: 40px;
  width: auto;

  & img {
    object-fit: contain !important;
  }
`

const BannerProductDetail: React.FC<BannerProductDetailProps> = ({
  fields,
}) => {
  const {
    backgroundImage,
  } = useStaticQuery<GatsbyTypes.bannerProductBackgroundQuery>(graphql`
    query bannerProductBackground {
      backgroundImage: file(
        relativePath: { eq: "home_header_background.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 2000)
        }
      }
    }
  `)

  return (
    <StyledBannerProductDetail>
      <StyledBackgroundImage image={{ localFile: backgroundImage }} alt="" />
      <div className="container h-100 position-relative">
        <div className="row align-items-center pt-lg-0 pt-5 h-100">
          <div className="col-lg-7 pt-lg-0 pt-5">
            <Content content={fields.description} className="mb-5" />
            <div className="row">
              {fields?.bannerusp.map((item, index) => (
                <div className="col-lg-6 d-flex flex-wrap flex-lg-nowrap mb-4 mb-lg-5" key={index}>
                  <div className="col-lg-2 px-0 mb-3 mb-lg-0">
                  <StyledIcon image={item.uspimage} alt="" />
                  </div>
                  <Content className="pt-0 pb-0 pl-lg-4" content={item.uspdescription} />
                </div>
              ))}
            </div>
            <ButtonDefault to="/resellers" className="text-uppercase mb-4">Find reseller</ButtonDefault>
          </div>
          <div className="col-lg-5 h-100 pb-lg-5 d-flex align-items-end justify-content-end">
            <StyledImage loading="eager" image={fields?.image} alt="" />
          </div>
        </div>
      </div>
    </StyledBannerProductDetail>
  )
}

export default BannerProductDetail
