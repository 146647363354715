import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
}

const StyledBannerDefault = styled.section`
  background-color: ${(props) => props.theme.color.face.main};
  height: 346px;

  @media screen and (max-width: 576px) {
    height: 200px;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.text.light};
  position: absolute;
  top: 85px;
  right: 0;
  color: rgba(255, 255, 255, 0.15);

  @media (max-width: 991px) {
    top: unset;
    bottom: 15px;
  }

  & p,
  h1,
  h2 {
    font-size: ${(props) => props.theme.font.size['120']};
    line-height: 120px;
    font-family: ${(props) => props.theme.font.family.secondary};

    @media screen and (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size['80']};
      line-height: 80px;
    }

    @media screen and (max-width: 768px) {
      font-size: ${(props) => props.theme.font.size['60']};
      line-height: 80px;
    }

    @media screen and (max-width: 576px) {
      font-size: ${(props) => props.theme.font.size['42']};
      line-height: 60px;
      padding-right: 15px;
    }
  }
`

const BannerDefaultLarge: React.FC<BannerDefaultProps> = ({ fields }) => (
  <StyledBannerDefault>
    <div className="container h-100 position-relative">
      <Content content={fields.description} className="text-uppercase" />
    </div>
  </StyledBannerDefault>
)

export default BannerDefaultLarge
