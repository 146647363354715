import React from 'react'

// Components
import BannerDefault from 'components/flex/Banner/BannerDefault'
import BannerHome from 'components/flex/Banner/BannerHome'
import BannerProductDetail from 'components/flex/Banner/BannerProductDetail'
import BannerBlogDetail from 'components/flex/Banner/BannerBlogDetail'
import BannerDefaultLarge from 'components/flex/Banner/BannerDefaultLarge'
import BannerWorkAt from 'components/flex/Banner/BannerWorkAt'

interface BannerShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
  location: any
}

interface BannersProps {
  [key: string]: any
}

const BannerShell: React.FunctionComponent<BannerShellProps> = ({
  fields,
  location,
}) => {
  const banners: BannersProps = {
    default: BannerDefault,
    home: BannerHome,
    detail: BannerProductDetail,
    workat: BannerWorkAt,
    blogDetail: BannerBlogDetail,
    defaultLarge: BannerDefaultLarge,
  }

  if (!fields.styleType || !banners[fields.styleType]) {
    return null
  }

  const Component = banners[fields.styleType]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default BannerShell
