/* eslint-disable no-undef */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'


// Third Party
import styled from 'styled-components'

const StyledBannerHome = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  height: 795px;
  position: relative;

  @media screen and (max-width: 991px) {
    height: 600px;
  }

  @media screen and (max-width: 576px) {
    height: fit-content;
    padding: 0 0 50px 0;
  }
`

const StyledImage = styled(Plaatjie)`
  width: 350px;
  height: auto;
  z-index: 9;

  @media screen and (max-width: 991px) {
    width: 200px;
    opacity: 0.7;
    position: absolute !important;
    bottom: 0;
    right: 0px;
    display: none !important;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.text.light};
  right: 0;
  position: relative;
  z-index: 10;
  margin-top: 60px;

  & h1 {
    color: rgba(255, 255, 255, 0.15);
    font-size: ${(props) => props.theme.font.size['120']};
    line-height: 120px;
    font-weight: 500;
    font-family: ${(props) => props.theme.font.family.secondary};
    position: absolute;
    white-space: nowrap;
    top: -160px;
    left: -40px;
  }

  & p {
    font-size: ${(props) => props.theme.font.size['18']};
    line-height: 21px;

    & b,
    strong {
      font-size: ${(props) => props.theme.font.size[20]};
      line-height: 24px;
    }
  }

  & ul {
    padding-left: 35px;
    padding-top: 20px;
  }

  @media screen and (max-width: 1200px) {
    & h1 {
      font-size: ${(props) => props.theme.font.size['100']};
      line-height: 100px;
      left: 0;
    }
  }

  @media screen and (max-width: 991px) {
    & h1 {
      position: relative;
      top: 0;
      font-size: ${(props) => props.theme.font.size['60']};
      line-height: 60px;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 80px;

    & h1 {
      font-size: ${(props) => props.theme.font.size[50]};
      line-height: 50px;
    }
  }

  @media screen and (max-width: 576px) {
    & h1 {
      font-size: ${(props) => props.theme.font.size[32]};
      line-height: 32px;
      padding-right: 15px;
      white-space: normal;
    }

    & p {
      & b,
      strong {
        font-size: ${(props) => props.theme.font.size[20]};
      }
    }
  }

  @media screen and (max-width: 350px) {
    & p {
      & b,
      strong {
        font-size: 16px;
      }
    }
  }
`

const StyledBackgroundImage = styled(Plaatjie)`
  opacity: 0.18;
  z-index: 6;
  height: 100%;
  width: 100%;
  position: absolute !important;
`

interface BannerHomeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
}

const BannerHome: React.FC<BannerHomeProps> = ({ fields }) => {
  const {
    backgroundImage,
  } = useStaticQuery<GatsbyTypes.bannerBackgroundImageQuery>(graphql`
    query bannerBackgroundImage {
      backgroundImage: file(
        relativePath: { eq: "home_header_background.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 2000)
        }
      }
    }
  `)

  return (
    <>
      <StyledBannerHome>
        <StyledBackgroundImage
          loading="eager"
          image={{ localFile: backgroundImage }}
          alt=""
        />
        <div className="container h-100 position-relative">
          <div className="row align-items-center pt-5 h-100">
            <div className="col-lg-6 d-flex align-items-center mt-lg-5">
              <Content content={fields.description} />
            </div>
            <div className="col-lg-6 d-flex justify-content-end">
              <div className="pr-5">
                <StyledImage loading="eager" image={fields.image} alt="" />
              </div>
            </div>
          </div>
        </div>
      </StyledBannerHome>
    </>
  )
}

export default BannerHome
