import React from 'react'

// Third Party
import styled from 'styled-components'

interface BannerBlogDetailProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
}

const StyledBannerBlogDetail = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  height: 125px;

  @media screen and (max-width: 576px) {
    height: 120px;
  }
`

const BannerBlogDetail: React.FC<BannerBlogDetailProps> = () => (
  <StyledBannerBlogDetail />
)

export default BannerBlogDetail
